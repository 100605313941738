import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import styled from "@emotion/styled";
import useHotKey from "./useHotKey";
import ContentWrapper from "../wrappers/contentWrapper"

const HeroImage = styled.div`
  display: grid;
  place-self: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  
  .boxA, .boxB {
    grid-area: 1/1;
  }
  
  .boxA {
    z-index: 3;
  }
  
  .SomeFakeTitle {
    margin-top: 10%;
    text-align: center;
      font-size: 2rem;
    font-weight: bold;
      color: white;
    }
  
  .SomeFakeCenter {
    text-align: center;
  }
`

const CenterThis = styled.div`
display: grid;
  justify-content: center;
  aligin-items: center;
`

const BigButton = styled.button`
  margin: 2em auto;
  width: 250px;
  position: relative;
  display: inline-block;
  padding: 0 20px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: var(--black);
  font-size: 0.85rem;
  font-weight: bold;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 30px;
  border: none;
  z-index: 1;

  &:hover {
    cursor: pointer;
    animation: animate 8s linear infinite;

    &::before {
      filter: blur(20px);
      opacity: 1;
      animation: animate 8s linear;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
`;

const sequence = [
    "ArrowUp",
    "ArrowUp",
    "ArrowDown",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowLeft",
    "ArrowRight",
    "b",
    "a",
    "Enter"
];

const Konami = () => {
    const [keysPressed, setKeysPress] = React.useState(false);
    useHotKey(sequence, () => setKeysPress(true));

    return (
        keysPressed && (
            <div>
                <ContentWrapper>
                    <CenterThis>
                        <HeroImage>
                            <div className="boxA">
                                <p className="SomeFakeTitle">TACOS OR ELSE!</p>
                                <p className="SomeFakeCenter"><BigButton onClick={() => setKeysPress(false)}>No More Tacos</BigButton></p>
                            </div>
                            <StaticImage className="boxB" src="./zim.jpg" alt="invader zim" />
                        </HeroImage>
                    </CenterThis>
                </ContentWrapper>
            </div>
        )
    );
};

export default Konami