import * as React from "react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

import general_bookkeeping from "../images/icons/general_bookkeeping.png"
import accounts_payable from "../images/icons/accounts_payable.png"
import accounts_receivable from "../images/icons/accounts_receivable.png"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Heading from "../components/typography/heading";
import SomeApp from "../components/konomi/konami";
import LocalBusinessSchemaHook from "../components/schema/localBusiness";

const HeroImage = styled.div`
  margin: 0 auto;
  width: 100%;
  max-height: 600px;
  overflow: hidden;
`

const MainIntro = styled.div`
  max-width: var(--siteWidth);
  margin: 0 auto;
  padding: 2em 1em 3em 1em;
`

const ServicesBox = styled.div`
  background: var(--grey);

  .SB_Inner {
    padding: 3em 1em;
    margin: 0 auto;
    max-width: var(--siteWidth);
    display: grid;
    grid-row-gap: 2em;

    @media (min-width: 1145px) {
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: space-between;
      align-items: center;
    }
  }

  .SB_Inner_Box {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    img {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 1em;
      color: white;
    }
  }
`

const AboutContainer = styled.div`
  max-width: var(--siteWidth);
  margin: 0 auto;
  padding: 3em 1em;

  display: grid;
  grid-row-gap: 2em;

  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }

  .AboutContainer_Img {
    place-self: center;
  }
`

const IndexPage = () => {
  return (
  <Layout>
    <Seo
      title="Bookkeeping Services, Quickbooks Certified"
      description="Bookkeeping services, including general bookkeeping, accounts payable and accounts receivable"
      canonical="https://labellebookkeeping.com"
    />
    <LocalBusinessSchemaHook />
    <SomeApp />
    <HeroImage>
      <StaticImage
        className="bgImage"
        src="../images/hero/labelle_bookkeeping_home_hero.jpg"
        quality={100}
        width={2560}
        objectPosition="50% 50%"
        alt="Labelle Bookkeeping Home Hero Image"
      />
    </HeroImage>
    <Heading>
        Greater Vancouver
        <br />
        Bookkeeping Services
    </Heading>

    <MainIntro>
      <p>
        If you're looking for a dependable bookkeeper with the skills and experience to keep your financials clean, then you're in the right place. Labelle Bookkeeping is a local bookkeeping firm dedicated to managing your day to day financial stress so you don't have to. I utilize the cloud-based accounting of Quickbooks Online so you can check in on your books whenever and wherever. No software set-up required!
        </p>
        <p>
          Every business has a unique set of requirements and solutions. I intend to learn about you and your work, so that I can ensure I'm offering you the absolute best quality of support that I can. When you're ready, give me a call or fill out the contact form and let's talk about you.
      </p>
    </MainIntro>

    <ServicesBox>
      <div className="SB_Inner">
        <div className="SB_Inner_Box">
          <img
            src={general_bookkeeping}
            width="120"
            height="120"
            alt="general bookkeeping services icon"
            loading="lazy"
          />
          <h2>
            General
            <br />
            bookkeeping
          </h2>
        </div>
        <div className="SB_Inner_Box">
          <img
            src={accounts_payable}
            width="120"
            height="120"
            alt="accounts payable services icon"
            loading="lazy"
          />
          <h2>
            Accounts
            <br />
            Payable
          </h2>
        </div>
        <div className="SB_Inner_Box">
          <img
            src={accounts_receivable}
            width="120"
            height="120"
            alt="accounts receivable services icon"
            loading="lazy"
          />
          <h2>
            Accounts
            <br />
            Receivable
          </h2>
        </div>
      </div>
    </ServicesBox>

    <AboutContainer>
      <div className="AboutContainer_Img">
        <StaticImage
          src="../images/labelle_bookkeepping_portrait.png"
          quality={100}
          alt="Labelle Portrait Photo"
          loading="lazy"
        />
      </div>
      <div>
        <p>
          My name is Stephanie. I hold a Bookkeeping Certificate from the
          University of the Fraser Valley, a Quickbooks ProAdvisor Certificate,
          and am a proud Canadian military daughter who's passionate about
          helping people succeed.
        </p>
        <p>
          My initial experiences as a bookkeeper stem from my time in the
          financial and procurement sector of the RCMP. Officially, my job
          description was to "meet tactical, routine and high risk, high-value
          project-oriented requirements in a timely and efficient manner." This
          is a very technical way of saying that I know how to handle projects
          with high demands, many details and can deliver results efficiently
          and on time.
        </p>

        {/*<p>*/}
        {/*  I've always aspired to be the best at what I do and with every*/}
        {/*  project. My ambitions grew. It eventually came time for me to start a*/}
        {/*  company of my own after working with larger firms over the years. But*/}
        {/*  my mission remains simple: exceed expectations!*/}
        {/*</p>*/}
      </div>
    </AboutContainer>
  </Layout>
)
}
export default IndexPage
